import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '50px'
  },
}));

const products = [
  {
    id: 1,
    name: "Havells",
    description: "Havells India Limited is an Indian multinational electrical equipment company, based in Noida. It was founded by Haveli Ram Gandhi, later sold to Qimat Rai Gupta who was his distributor. The company manufactures home appliances, lighting for domestic, commercial and industrial applications, LED lighting, fans, modular switches and wiring accessories, water heaters, industrial and domestic circuit protection switchgear, industrial and domestic cables and wires, induction motors, and capacitors among others. Havells owns brands like Havells, Lloyd, Crabtree, Standard Electric, Reo and Promptec.",
  },
  {
    id: 2,
    name: "Bajaj",
    description: "Bajaj Electricals’ business portfolio spans Consumer Products (Appliances, Fans, Cookware) and Lighting Solutions (Consumer and Professional Lighting). They are a front runner in the industry with their key brand offerings comprising BAJAJ, Nirlep, Morphy Richards and Nex. They have an expansive network of 18 branch offices, 660+ distributors, and ~ 2 lakh retail outlets across India, combined with over 620 consumer care centres reaching 19,000 pin codes.",
  },
  {
    id: 3,
    name: "Usha",
    description: "Usha International Limited (UIL) an Indian multinational manufacturer and marketer of home appliances, headquartered in Gurugram, Harayana, India. The company has annual revenue of approximately $1,332,699, 3700 employees working from 16 regional locations, manufacturing and technology research centers around the world. The company has an all India retail presence with 60 company showrooms and logistics supported by 33 warehouses.",
  },
  {
    id: 4,
    name: "Crompton",
    description: "Crompton Greaves Consumer Electricals Limited (also known as Crompton) is an Indian electrical equipment company based in Mumbai, India. The company has lighting and electrical consumer durables including LED lighting, fans, pumps, and household appliances like water heaters, air coolers, and kitchen appliances. The company was established in 1937 as Crompton Parkinson Works Limited, a wholly-owned subsidiary of Crompton Parkinson. In 1947, it was acquired by Karam Chand Thapar. The company was established in 2016 as an outcome of the demerger of Crompton Greaves Limited which separated the latter's consumer goods business from the power and industrial systems segment.",
  }
  // Add more products here
];

const ProductCard = ({ product }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {product.name}
        </Typography>
      </CardContent>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={handleAccordionChange} />}
          aria-controls="product-details"
          id="product-details-summary"
        >
          <Typography>Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{product.description}</Typography>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

const Product = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Product;

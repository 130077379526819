import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Container,
} from "@material-ui/core";
import {
  Home as HomeIcon,
  AccountCircle as AccountCircleIcon,
  Category as ProductIcon,
  Call as ContactIcon,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#44AAAF",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
  iconText: {
    marginLeft: "5px",
  },
}));

const Navbar = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  const goToProduct = () => {
    navigate("/product");
  };

  const goToContact = () => {
    navigate("/contact");
  };

  return (
    <Box>
      <AppBar className={classes.appBar} position="static">
        <Container>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Vishwakarma Electrical Shop
            </Typography>
            <IconButton color="inherit" onClick={() => goToHome()}>
              <HomeIcon />
              <Typography variant="subtitle2" className={classes.iconText}>
                Home
              </Typography>
            </IconButton>
            <IconButton color="inherit" onClick={() => goToProduct()}>
              <ProductIcon />
              <Typography variant="subtitle2" className={classes.iconText}>
                Products
              </Typography>
            </IconButton>

            <IconButton color="inherit" onClick={() => goToContact()}>
              <ContactIcon />
              <Typography variant="subtitle2" className={classes.iconText}>
                Contact
              </Typography>
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default Navbar;

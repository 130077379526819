import React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
    minHeight: "100vh",
  },
  content: {
    paddingTop: "0vh",
  },
  topContent: {
    backgroundImage: `url(${require("../Images/VE_ContactUs.png")})`,
    height: "25vw",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    marginBottom: 100,
    opacity: 1,
  },
  main: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },
}));

const Contact = () => {
  const classes = useStyles();
  window.scrollTo(0, 0);

  return (
    <Box key="contactPageKey" className={classes.root}>
      <Box className={classes.topContent}>
        <Box style={{ textAlign: "center" }}></Box>
      </Box>
      <Container className={classes.content}>
        <Box className={classes.main}>
          <Typography gutterBottom variant="h3" style={{ color: "black" }}>
            Let us know your needs
          </Typography>
        </Box>
        <Box className={classes.main}>
          Call us at :&nbsp;
          <a
            href="tel:9334539594"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            9334539594
          </a>
        </Box>
        <Box className={classes.main}>
          Email us at :&nbsp;
          <a
            href="mailto:hello@vishwakarmaelectric.in"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            hello@vishwakarmaelectric.in
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;

import React from "react";
import {
  Container,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import BuildIcon from "@material-ui/icons/Build";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Map from "./map";

const useStyles = makeStyles({
  root: {
    margin: 20,
  },
  root2: {
    margin: 20,
  },
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: 250,
    padding: 20,
  },
  icon: {
    fontSize: 60,
    marginBottom: 20,
  },
});

const Home = () => {
  const classes = useStyles();

  return (
    <Container>
      <Typography variant="h4" align="center" style={{ marginTop: "20px" }}>
        Welcome to our Services
      </Typography>
      <Grid container spacing={3} className={classes.root}>
        <Grid item sm={4}>
          <Card className={classes.card}>
            <CardMedia>
              <ShoppingCartIcon
                style={{ color: "green" }}
                className={classes.icon}
              />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" align="center">
                Electrical Items Selling
              </Typography>
              <Typography variant="body1" align="center">
                We offer a diverse array of electrical items, catering to all
                types of needs and specifications for any and all of your
                electrical requirements.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={4}>
          <Card className={classes.card}>
            <CardMedia>
              <BuildIcon style={{ color: "grey" }} className={classes.icon} />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" align="center">
                Electrical Appliance Repairs
              </Typography>
              <Typography variant="body1" align="center">
                We provide comprehensive repair services for all kinds of
                electrical appliances, ensuring your appliances are running
                smoothly and efficiently.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={4}>
          <Card className={classes.card}>
            <CardMedia>
              <HomeIcon style={{ color: "brown" }} className={classes.icon} />
            </CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h5" align="center">
                Home Wiring Consultancy
              </Typography>
              <Typography variant="body1" align="center">
                We offer expert home wiring consultancy services to help you
                design and implement safe, efficient, and reliable electrical
                systems for your home.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.root2}>
        <Card
          style={{
            width: "100%",
            background: "linear-gradient(45deg, #44AF9D, #4BB7BE)",
            color: "white",
          }}
        >
          <CardContent>
            <Typography variant="h5" style={{ marginBottom: 20 }}>
              Visit Our Shop At
            </Typography>
            <Typography variant="h6">
              Ramesh Colony, Dariyapur road, Kurthaul - 804453 (near Rajendra
              Awasiya School)
            </Typography>
            <Typography variant="h7">
              Find us on Google map:{" "}
              <a
                href="https://www.google.com/maps/place/25%C2%B033'28.5%22N+85%C2%B007'30.4%22E/@25.5579734,85.125011,21z/data=!4m10!1m5!3m4!2zMjXCsDMzJzI4LjUiTiA4NcKwMDcnMzAuNCJF!8m2!3d25.5579167!4d85.1251111!3m3!8m2!3d25.557926!4d85.12512"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                Vishwakarma Electrical Shop
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <CardContent style={{paddingLeft: '7%'}}>
        <Map />
      </CardContent>
    </Container>
  );
};

export default Home;

import React, { useEffect } from "react";

const TakuWidget = ( props ) => {

    useEffect(() => {
      // Function to initialize the Taku script
      const initializeTakuScript = () => {
        // Create script element
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.src = 'https://cdn.taku-app.com/js/latest.js';
        // Append script element to document
        document.body.appendChild(scriptElement);
  
        // Initialize Taku when the script loads
        scriptElement.onload = () => {
          window.Taku('news:boot', {
            api_public_key: props.apiKey,
            position: 'right',
            launcher_options: {
              bg_color: '#44AAAF',
              text_color: '#ffffff',
              text: 'What\'s new',
            },
          });
        };
      };
  
      // Check if Taku is already defined on window
      if (typeof window.Taku !== 'function') {
        initializeTakuScript();
      }
    }, [props.apiKey]);
  
    return null; // This component does not render anything itself
  };

  const Footer = () => {
    const key = process.env.REACT_APP_TAKU_API_KEY;

    return (
        <TakuWidget apiKey={key}/>
    );
  }

  export default Footer;